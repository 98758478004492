/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*,
ion-input {
    font-family: 'Montserrat', sans-serif;
}

ion-content {
    --background: #f5f6f9;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

@media only screen and (min-width: 768px) {
    .modal-wrapper {
        border-radius: 12px !important;
    }
}

@media only screen and (min-width: 1000px) and (min-height: 500px) {
    .product_modal .modal-wrapper {
        --width: 1000px;
        --height: 500px;
        .header_block {
            ion-button {
                margin-right: 10px;
            }
        }
    }
}

@media only screen and (min-width: 500px) and (min-height: 1000px) and (orientation:portrait) {
    .product_modal .modal-wrapper {
        --height: 1000px;
    }
}

@media only screen and (max-width: 767px) {
    .modal-wrapper {
        border-radius: 0px !important;
    }
}

ion-fab.cart_fab {
    right: 16px;
    bottom: 64px
}

ion-fab-button {
    --background: #84BB00;
}

.container {
    min-height: 100%;
}

.button-outline {
    --border-width: 1px;
}

ion-alert {
    --max-width: 80% !important;
    --min-width: 350px !important;
}

.alert-title,
.alert-message {
    text-align: center;
}

.alert-button-inner {
    color: black;
}

.menu {
    display: flex;
    position: fixed;
    background-color: black;
    z-index: 1;
    color: white;
    width: 100%;
    justify-content: space-around;
    padding: 9px;
    .logo {
        height: 70px;
        margin-right: 64px;
    }
    .menu_list {
        width: 40%;
        display: flex;
        align-self: center;
        justify-content: space-around;
        .menu_item {
            cursor: pointer;
            margin: 5px 0px 0;
            padding: 0px 4px;
            font-weight: 300;
            font-size: 14px;
        }
        .menu_item.active {
            font-weight: 500;
            border-bottom: 1px solid white;
            padding-bottom: 5px;
        }
    }
    .cart {
        margin-left: 64px;
        display: flex;
        align-self: center;
        cursor: pointer;
        background: transparent;
        color: white;
        border: 1px solid white;
        font-size: 16px;
        border-radius: 12px;
        padding: 9px 16px;
        margin-right: 16px;
        width: fit-content;
        line-height: 16px;
    }
}

ion-toast {
    --background: #84BB00;
    --border-radius: 12px;
}